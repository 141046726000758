<script>
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useLocalization } from '/~/composables/localization'
import { useUserEvents } from '/~/composables/user'
import CompetitionTerms from './competition-terms.vue'

export default {
  components: {
    BaseMdl,
    BaseButton,
    CompetitionTerms,
  },
  props: {
    competition: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { termsAndConditionsVisited } = useUserEvents()
    const { translate } = useLocalization()

    termsAndConditionsVisited({ type: 'modal', name: 'Competition terms' })

    return {
      translate,
    }
  },
  methods: {
    print() {
      window.print()
    },
  },
}
</script>

<template>
  <base-mdl
    :padding="false"
    width="sme"
    height="lg"
    fullscreen="mobile"
    :title="`Terms ${translate('common.and')} Conditions`"
  >
    <template #menu>
      <base-button icon="plain/printer" @click="print" />
    </template>
    <competition-terms
      :competition="competition"
      class="px-[30px] pb-[30px] pt-5"
    />
    <portal to="print-destination">
      <competition-terms :competition="competition" />
    </portal>
  </base-mdl>
</template>
